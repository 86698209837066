import type { LocalTiktokEmail, LocalStorageFunctions } from './types';

const TIKTOK_EMAIL: string = 'tiktok-email';

const getLocalStorageTiktokEmail = (fn: LocalStorageFunctions) => (): string => fn.readObject(TIKTOK_EMAIL);
const setLocalStorageTiktokEmail = (fn: LocalStorageFunctions) => (email: string) => fn.writeObject(TIKTOK_EMAIL, email);

const tiktokEmail = (fn: LocalStorageFunctions): LocalTiktokEmail => ({
  getLocalStorageTiktokEmail: getLocalStorageTiktokEmail(fn),
  setLocalStorageTiktokEmail: setLocalStorageTiktokEmail(fn)
});

export default tiktokEmail;
