import type { HTTPFunctions } from '../types';

import authUser from './authUser';
import media from './media';

const auth = (props: HTTPFunctions) => ({
  ...authUser(props),
  ...media(props)
});

export default auth;
