import { GmailSignInData, HTTPFunctions, SignIn, SignUpData } from '../types';

type ResponseData = {
  data: any;
}

const getResponseData = (response: ResponseData) => response.data;

const authUser = ({ wrapper, instance, get, put, postGmail, del, post }: HTTPFunctions) => ({
  signUp: (data: SignUpData) => wrapper(instance.post("/users/open", data)),
  logout: () => localStorage.removeItem("user"),
  signIn: (data: SignIn) => {
    const params = new URLSearchParams();
    params.append("username", data.email);
    params.append("password", data.password);
    if (data.reportId) {
      params.append("client_id", data.reportId);
    }
    return wrapper(instance.post("/login/access-token", params));
  },
  gmailSignIn: (data: GmailSignInData) => postGmail("/swap_token", data),
  passwordRecovery: (username: string) =>
    wrapper(instance.post(`/password-recovery/${username}`, {}).then(getResponseData)),
  resetPassword: (new_password: string, token: string) =>
    wrapper(instance.post("/reset-password/", { new_password, token }).then(getResponseData)),
  newPassword: (old_password: string, new_password: string) =>
    put( '/users/password', {old_password, new_password}).then(getResponseData),
  validateEmail: (token: string) => wrapper(instance.get(`/users/email_confirmation/${token}`)),
  getAccountSettings: () => get('/users/me'),
  setAccountSettings: (data: any) => put('/users/me', data),
  deleteAccountSettings: () => del('/users/me'),
});

export default authUser;
