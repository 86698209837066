// import { AxiosRequestHeaders } from 'axios';

import getLocal from 'services/getLocal';
// AxiosRequestHeaders
const authHeader = (): any => {
  const user = getLocal(localStorage).auth.getUser();
  if (user?.access_token) {
    return {
      Authorization: 'Bearer ' + user.access_token,
      apikey: process.env.REACT_APP_API_KEY || '',
      shop: 'the-cribs'
    } as any;
  } else {
    return {
      apikey: process.env.REACT_APP_API_KEY || '',
      shop: 'the-cribs'
    } as any;
  }
};

export default authHeader;
