import { GA4Property } from 'services/local/types';
import type { HTTPFunctions } from '../types';

const FIELDS = [
  'id',
  'created_time',
  'message',
  'permalink_url',
  'full_picture',
  'shares',
  'reactions.summary(true)',
  'dynamic_posts',
  'comments{message,like_count,created_time,from,reactions.summary(true)}',
  'insights.metric(post_clicks,post_impressions,post_impressions_unique)',
];

const INSTAGRAM_FIELDS = [
  'id',
  'caption',
  'timestamp',
  'like_count',
  'media_url',
  'media_type',
  'comments_count',
  'insights.metric(reach,shares,impressions)',
  'comments{like_count,timestamp,user,username,text}'
];

const FACEBOOK_PICTURES = [
  'full_picture',
  'attachments{media_type,media{image,source}}'
];

const INSTAGRAM_PICTURES = [
  'media_type',
  'media_url',
  'thumbnail_url',
  'children{media_url}'
];

const ADS = [
  'name',
  'insights{spend,clicks,reach,impressions,actions,conversions,ctr,cpp,cpm,cpc}',
  'adcreatives{link_url,instagram_permalink_url,name,body,image_url,video_id,thumbnail_url,object_id,object_story_id,object_type,status,title,source_instagram_media_id,call_to_action_type,effective_instagram_media_id,effective_object_story_id,object_url,asset_feed_spec,object_story_spec}'
];

type MetaPostBody = {
  fields: string[];
  email: string;
  limit?: number;
  before?: string;
  after?: string;
}

const media = ({ get, post, del }: HTTPFunctions) => ({
  revokeGA4: (email: string) => del(`google-analytics/revoke/${email}`),
  revokeInstagram: (email: string) => del(`meta/instagram/revoke/${email}`),
  revokeFacebook: (email: string) => del(`meta/facebook/revoke/${email}`),
  revokeTiktok: (email: string) => del(`tiktok/revoke/${email}`),
  sendGA4Code: (code: string, email: string) => get(`google-analytics/callback?code=${code}&email=${email}`),
  sendInstagramCode: (code: string) => get(`meta/instagram/callback?code=${code}`),
  sendFacebookCode: (code: string) => get(`meta/facebook/callback?code=${code}`),
  sendTiktokCode: (code: string, email: string) => get(`tiktok/callback?code=${code}&email=${email}`),
  getAuthStore: (email: string, url: string) => get(`/spf/callback?email=${email}&${url}`),
  gA4verifyEmail: (email: string) => get(`google-analytics/verify?email=${email}`),
  shopifyVerifyEmail: (email: string) => get(`spf/verify?email=${email}`),
  instagramVerifyEmail: (email: string) => get(`meta/instagram/verify?email=${email}`),
  facebookVerifyEmail: (email: string) => get(`meta/facebook/verify?email=${email}`),
  tiktokVerifyEmail: (email: string) => get(`tiktok/verify?email=${email}`),
  getShopifyOAuthUrl: (store: string) => get(`/spf/oauth-url/${store}`),
  sendGA4Account: (accountId: string, property: GA4Property, email: string) => post('google-analytics/grant/', {
    email, property_name: property.name, property_display_name: property.display_name, account_id: accountId
  }),
  selectFacebookPage: (pageId: string, email: string) => post('/meta/facebook/grant/', { page_id: pageId, email }),
  getFacebookAccounts: (email: string) => get(`/meta/me/accounts?email=${email}`),
  getFacebookPosts: (accountId: string, email: string, limit: number = 10, before?: string, after?: string) => {
    const body: MetaPostBody = {fields: FIELDS, email, limit};
    if (before) body.before = before;
    if (after) body.after = after;
    return post(`/meta/facebook/${accountId}/posts`, body);
  },
  getInstagramPosts: (accountId: string, email: string, limit: number = 10, before?: string, after?: string) => {
    const body: MetaPostBody = {fields: INSTAGRAM_FIELDS, email, limit};
    if (before) body.before = before;
    if (after) body.after = after;
    return post(`/meta/instagram/${accountId}/posts`, body);
  },
  getFacebookPostDetails: (postId: string, email: string) => {
    return post(`/meta/post-details/${postId}?email=${email}`, FACEBOOK_PICTURES);
  },
  getInstagramPostDetails: (postId: string, email: string) => {
    return post(`/meta/post-details/${postId}?email=${email}`, INSTAGRAM_PICTURES);
  },
  getFacebookAdsAccount: (email: string) => get(`/meta/facebook/ads/accounts?email=${email}`),
  getFacebookAds: (accountId: string, email: string, limit: number = 100, before?: string, after?: string) => {
    let url = `/meta/${accountId}/ads?email=${email}&limit=${limit}`;
    if (before) url += `&before=${before}`;
    if (after) url += `&after=${after}`;
    return post(url, ADS);
  }
});

export default media;
