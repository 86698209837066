import type { LocalInstagramEmail, LocalStorageFunctions } from './types';

const INSTAGRAM_EMAIL: string = 'instagram-email';

const getLocalStorageInstagramEmail = (fn: LocalStorageFunctions) => (): string => fn.readObject(INSTAGRAM_EMAIL);
const setLocalStorageInstagramEmail = (fn: LocalStorageFunctions) => (email: string) => fn.writeObject(INSTAGRAM_EMAIL, email);

const instagramEmail = (fn: LocalStorageFunctions): LocalInstagramEmail => ({
  getLocalStorageInstagramEmail: getLocalStorageInstagramEmail(fn),
  setLocalStorageInstagramEmail: setLocalStorageInstagramEmail(fn)
});

export default instagramEmail;
