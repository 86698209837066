import type { HTTPFunctions } from './types';
import type { ProductTemplate, ProductTemplateWithId } from 'pages/NewECommerce/types';

const api = ({ get, post, postFile, put, del }: HTTPFunctions) => ({
  newCorrelations: (formData: any, {template_id, customized_target, audience_id}: any) => postFile(`/correlation/?template_id=${template_id}&audience_id=${audience_id}&document_purpose_id=${customized_target}`, formData),
  newCorrelationsShopify: (formData: any, {store, email}: any) => postFile(`/correlation/spf/?email=${email}&store=${store}`, formData),
  getCorrelations: (id: string) => get(`/correlation/${id}`),
  getCorrelationsSet: (id: string) => get(`/correlation/my-correlations-set/${id}`),
  getCorrelationsSets: (page: number) => get(`/correlation/my-correlations-set/page/${page}/11/`),
  delCorrelationSet: (id: string) => del(`/correlation/my-correlations-set/${id}`),
  getProduct: (id: string) => get(`/products/${id}`),
  updateNameCorrelationSet: (id: string, name: string) => put(`/correlation/name/${id}`, {name}),
  createProductDescription: (data: any) => post('/products/ia/description', data),
  rewriteWithAI: (data: any) => post('/products/ia/description/rewrite', data),
  getOptimisedDescriptionAnalysis: (id: string) => get(`/products/rewrite/${id}`),
  reanalyseDescription: (correlationId: string, product_id: string, description: string, optimized_for: string) =>
    put(`/products/ia/description/rewrite/${correlationId}`, { product_id, description, optimized_for }),
  getNecessaryWords: (data: any) => post('/products/ia/description/necessary-words', data),
  addCorrelationTemplate: (data: ProductTemplate) => post('/correlation-templates/', data),
  getCorrelationTemplates: () => get('/correlation-templates/'),
  downloadCorrelationTemplate: (id: number) => get(`/correlation-templates/download/${id}`),
  delCorrelationTemplate: (id: number) => del(`/correlation-templates/${id}`),
  editCorrelationTemplate: (data: ProductTemplateWithId) => put(`/correlation-templates/${data.id}`, data),
  addShopifyStore: (store: string, email: string) => post('/spf/store/', { store, email }),
  getShopifyStores: (email: string) => get(`/spf/store/?email=${email}`),
  getGAReport: (email: string, property: string, year: number, month: number) => get(`/ga/summary/${email}/${property}/${year}/${month}`)
});

export default api;
