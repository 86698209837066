import type { LocalShopifyEmail, LocalStorageFunctions } from './types';

const SHOPIFY_EMAIL: string = 'shopify-email';

const getLocalStorageShopifyEmail = (fn: LocalStorageFunctions) => (): string => fn.readObject(SHOPIFY_EMAIL);
const setLocalStorageShopifyEmail = (fn: LocalStorageFunctions) => (email: string) => fn.writeObject(SHOPIFY_EMAIL, email);

const shopifyEmail = (fn: LocalStorageFunctions): LocalShopifyEmail => ({
  getLocalStorageShopifyEmail: getLocalStorageShopifyEmail(fn),
  setLocalStorageShopifyEmail: setLocalStorageShopifyEmail(fn)
});

export default shopifyEmail;
