import type { LocalFacebookEmail, LocalStorageFunctions } from './types';

const FACEBOOK_EMAIL: string = 'facebook-email';

const getLocalStorageFacebookEmail = (fn: LocalStorageFunctions) => (): string => fn.readObject(FACEBOOK_EMAIL);
const setLocalStorageFacebookEmail = (fn: LocalStorageFunctions) => (email: string) => fn.writeObject(FACEBOOK_EMAIL, email);

const facebookEmail = (fn: LocalStorageFunctions): LocalFacebookEmail => ({
  getLocalStorageFacebookEmail: getLocalStorageFacebookEmail(fn),
  setLocalStorageFacebookEmail: setLocalStorageFacebookEmail(fn)
});

export default facebookEmail;
